<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-4">
      <template v-if="loading">
        ...
      </template>
      <template v-else>
        {{ signal.name }}
      </template>
    </h3>
    <template v-if="loading">
      <div class="rounded-xl">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <dl class="space-y-6 divide-y divide-gray-900/10 pt-4 border-b pb-4" :class="[$theme.cardBorderColor]">
        <Disclosure as="div" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left" :class="[$theme.baseTextColor]">
              <span class="text-base font-semibold leading-7">Averaging</span>
              <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2">
            <div>
              <div class="mt-4">
                <SwitchGroup as="div" class="flex items-center justify-between">
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]" passive>
                    Use averaging
                  </SwitchLabel>
                  <SwitchDescription as="span" class="text-xs" :class="[$theme.cardLabelTextColor]">
                    You can set up averaging for this signal
                  </SwitchDescription>
                </span>
                  <Switch v-model="form.is_average" :class="[form.is_average ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
                    <span aria-hidden="true" :class="[form.is_average ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                  </Switch>
                </SwitchGroup>
              </div>
              <template v-if="form.is_average">
                <div class="mt-4">
                  <label for="roi_target" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                    Type Martingale
                  </label>
                  <div class="mt-2">
                    <div class="flex items-center justify-between">
                      <div class="w-2/4">
                        <select
                            v-model="form.dca_martingale_type"
                            @change="onChangeTypeMartingale"
                            id="roi_type"
                            class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                            :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                        >
                          <option value="percent">Percent</option>
                          <option value="amount">Fix amount</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <label for="roi_target" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                    DCA Martingale
                  </label>
                  <div class="mt-2">
                    <div class="flex items-center justify-between">
                      <div class="w-2/4">
                        <input
                            v-model="form.dca_martingale"
                            type="number"
                            inputmode="decimal"
                            step="any"
                            id="roi_target"
                            class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                            :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                      ]"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!--
                <div class="mt-4">
                  <label for="roi_target" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                    Maximum drawdown (%)
                  </label>
                  <div class="mt-2">
                    <div class="flex items-center justify-between">
                      <div class="w-2/4">
                        <input
                            v-model="form.average_drawdown_percent"
                            type="number"
                            inputmode="decimal"
                            step="any"
                            id="roi_target"
                            class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                            :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                      ]"
                        />
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="mt-4">
                  <label for="roi_target" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                    Number of averages
                  </label>
                  <div class="mt-2">
                    <div class="flex items-center justify-between">
                      <div class="w-2/4">
                        <select
                            v-model="form.max_count_average"
                            id="roi_type"
                            class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                            :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                        >
                          <option v-for="i in 10" :value="i" :key="i">{{ i }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </dl>
      <div class="mt-5">
        <button
            @click="updateSettings"
            type="button"
            class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
            :class="[formProcessing ? 'opacity-50 pointer-events-none' : '', $theme.inverseBaseBg]"
        >
          <template v-if="formProcessing">
            <ButtonSpinner />
          </template>
          {{ $t('update_settings') }}
        </button>
        <div class="mt-2 text-xs text-center" :class="[$theme.cardLabelTextColor]">
          {{ $t('note_update_settings') }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { addBackButton, hideBackButton } from "@/helpers/telegramHelper";
import {capitalizeFirstLetter} from "@/helpers/stringHelper"
import LoadingBlock from "@/components/LoadingBlock.vue";
import {MinusSmallIcon, PlusSmallIcon} from "@heroicons/vue/24/outline";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue'
import ButtonSpinner from "@/components/ButtonSpinner.vue";

export default {
  components: {
    ButtonSpinner,
    MinusSmallIcon,
    PlusSmallIcon,
    LoadingBlock,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  },
  methods: {
    onChangeTypeMartingale() {
      if (this.form.dca_martingale_type === 'percent') {
        this.form.dca_martingale = 1.5;
      } else {
        this.form.dca_martingale = 0;
      }
    },

    onLoadSignal() {
      const id = this.$route.params.id;

      this.$api.get(`/signals/${id}`).then(result => {
        console.log('result', result.data.data);
        this.signal = result.data.data;
        this.loading = false;

        this.onLoadSettings();
      })
    },

    onLoadSettings() {
      const id = this.$route.params.id;

      this.$api.get(`/signals/${id}/settings`).then(result => {
        console.log('result', result.data);

        if (result.data.is_average) {
          this.form = result.data;
        }
      })
    },

    updateSettings() {
      const id = this.$route.params.id;

      this.formProcessing = true;

      this.$api.post(`/signals/${id}/settings`, this.form).then(result => {
        console.log('result', result.data);

        this.formProcessing = false;
      })
    },
  },

  data() {
    return {
      loading: true,
      signal: null,
      capitalizeFirstLetter,

      formProcessing: false,

      form: {
        is_average: false,
        average_drawdown_percent: 50.00,
        max_count_average: 2,
        dca_martingale_type: 'percent',
        dca_martingale: 1.5,
      },
    }
  },

  mounted() {
    this.onLoadSignal();

    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>
