<template>
  <div class="flex-col flex justify-between items-center h-full">
    <div>
      <div class="text-center flex items-center justify-center mt-5">
        <img src="/img/512.gif" alt="pr" class="w-36 rounded-xl" />
      </div>
      <div class="text-3xl font-semibold text-center mt-5">
        {{ $t('try_for_free') }}
      </div>
      <div class="mt-3">
        <div class="text-center">
          {{ $t('activate_trial_period_n') }}
        </div>
        <div class="mt-2 text-center">
          {{ $t('activate_trial_period_n2') }}
        </div>
      </div>
    </div>
    <div class="mt-5 absolute w-full bottom-0 left-0 px-4 pb-5">
      <div class="mb-2 text-sm text-center opacity-50">{{ $t('link_card_note') }}</div>
      <router-link
          to="/link-card"
          class="flex w-full items-center text-white justify-center rounded-xl px-3 py-3 font-semibold focus-visible:outline"
          :class="[
              $theme.inverseBaseBg
          ]"
      >
        {{ $t('connect_card') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrialStart',

  data() {
    return {
    }
  },

  mounted() {
  },
}
</script>