<template>
  <div class="relative z-10">
    <img
      :src="iconPath"
      class="w-7 border-2 rounded-full bg-white"
      :alt="symbol"
      :class="[$theme.borderColorCoin]"
      @error="handleImageError"
    />
  </div>
</template>

<script>
export default {
  props: ['symbol'],

  computed: {
    processedCoin() {
      return this.symbol.replace(/USDT|USDC/gi, '').toLowerCase()
    },

    iconPath() {
      try {
        return require(`../../../node_modules/cryptocurrency-icons/svg/color/${this.processedCoin}.svg`)
      } catch (e) {
        return `https://cryptofonts.com/img/SVG/${this.processedCoin}.svg`
      }
    }
  },

  methods: {
    handleImageError(event) {
      event.target.src = `/img/${this.processedCoin}.png`
    }
  }
}
</script>
