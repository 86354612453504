<template>
  <div>
    <template v-if="transaction_status === 'completed'">
      <span class="text-green-600 text-sm">
        {{ $t('done') }}
      </span>
    </template>
    <template v-if="transaction_status === 'pending'">
      <span class="text-yellow-500 text-sm">
        {{ $t('pending') }}
      </span>
    </template>
    <template v-if="transaction_status === 'fail'">
      <span class="text-red-500 text-sm">
        {{ $t('fail') }}
      </span>
    </template>
  </div>
</template>
<script>
export default {
  props: [
    'transaction_status'
  ]
}
</script>
