<template>
  <div
    class="min-h-screen"
    :class="[
      $theme.baseBg,
      [
        'setting',
        'stats',
        'home',
        'notifications',
        'signals',
        'language',
        'orders',
        'refs',
        'faq',
        'tariff',
        'profile',
        'security',
        'show-signal',
        'ref-stats',
        'incomplete',
        'terms',
        'link-card',
        'payment-cards',
        'user-logs',
        'referral',
        'stats-exchange',
      ].includes($route.name) ? $theme.secondBaseBg : '',
      $theme.baseTextColor,
    ]"
  >
    <template v-if="isShowAlerts">
      <EmergencyAlerts :list_errors="readyTrading" />
    </template>
  <router-view></router-view></div>
  <template v-if="isShowNavbar">
    <AppNavbar />
  </template>
  <template v-if="$settings.support_links">
    <SupportWidget :links="$settings.support_links" />
  </template>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import SupportWidget from "@/components/SupportWidget.vue";
import EmergencyAlerts from "@/components/elem/EmergencyAlerts.vue";

export default {
  name: 'App',

  components: {
    SupportWidget,
    AppNavbar,
    EmergencyAlerts,
  },

  computed: {
    isShowNavbar() {
      return (this.$user.reg_status === 'COMPLETED' || this.$user.reg_status === 'SKIP_SETUP')
          && this.$route.name !== 'two-factor-auth';
    },

    enabledSignals() {
      return this.$store.state.enabledSignals;
    },

    isShowAlerts() {
      if (!this.isShowNavbar) {
        return false;
      }

      return this.readyTrading.tariff_selected
          || this.readyTrading.tariff_expired
          || this.readyTrading.exchange_disconnected
          || this.readyTrading.signals_disabled
          || this.readyTrading.balance_unavailable;
    }
  },

  methods: {
    checkReadyTrading() {
      this.$api.get('/user/ready-trading').then((result) => {
        this.readyTrading.tariff_selected = result.data.tariff_selected;
        this.readyTrading.tariff_expired = result.data.tariff_expired;
        this.readyTrading.signals_disabled = result.data.signals_disabled;
        this.readyTrading.balance_unavailable = result.data.balance_unavailable;
        this.readyTrading.exchange_disconnected = result.data.exchange_disconnected;
      });
    }
  },

  data() {
    return {
      telegramReady: false,
      readyTrading: {
        tariff_selected: false,
        tariff_expired: false,
        signals_disabled: false,
        balance_unavailable: false,
        exchange_disconnected: false
      },
    }
  },

  mounted() {
    console.log(this.$user.settings.signals);
    this.$store.commit('updateEnabledSignals', this.$user.settings.signals);
    this.checkReadyTrading();

    if (window.Telegram && window.Telegram.WebApp) {
      this.telegramReady = true

      const startParam = window.Telegram.WebApp.initDataUnsafe?.start_param;
      if (startParam && startParam.indexOf('faq') !== -1) {
        const parts = startParam.split("-");

        this.$router.push({ name: parts[0], query: { startitem: parts[1] } });
      }

      if (window.Telegram.WebApp.isVersionAtLeast('7.0')) {
        window.Telegram.WebApp.SettingsButton.show();

        window.Telegram.WebApp.SettingsButton.onClick(() => {
          this.$router.push({ name: 'setting' });
        });
      }
    }
  },
}
</script>
