<template>
  <div class="text-sm" :class="[$theme.baseTextColor]">
    <div v-for="block in content.blocks" :key="block.id">
      <p v-if="block.type === 'paragraph'" class="mt-2" v-html="formatText(block.data.text)"></p>

      <div v-if="block.type === 'list'" class="mt-3">
        <ul v-if="block.data.style === 'unordered'" class="list-disc list-inside pl-3 space-y-2">
          <li v-for="(item, index) in block.data.items" :key="index" v-html="formatText(item)"></li>
        </ul>
        <ol v-else class="list-decimal list-inside pl-3 space-y-2">
          <li v-for="(item, index) in block.data.items" :key="index" v-html="formatText(item)"></li>
        </ol>
      </div>

      <component v-if="block.type === 'header'"
                 :is="'h' + block.data.level"
                 class="font-semibold mt-2"
                 :class="getHeaderClass(block.data.level)"
                 v-html="block.data.text">
      </component>
      <template v-if="block.type === 'raw'">
        <div v-html="block.data.html" class="my-2 ui-raw-html"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentRenderer',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  methods: {
    getHeaderClass(level) {
      switch (level) {
        case 1:
          return 'text-2xl';
        case 2:
          return 'text-xl';
        case 3:
          return 'text-lg';
        default:
          return 'text-base';
      }
    },
    formatText(text) {
      if (!text) return '';
      const urlRegex = /(https?:\/\/\S+)/g;
      return text.replace(
          urlRegex,
          '<a href="$1" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-800 underline">$1</a>'
      );
    },
  },

  mounted() {
    console.log(this.content.blocks)
  }
}
</script>
